.articles-cards-with-images-container {
  padding: 80px 0;
  background: #f0f0f0;
  position: relative;
  .container {
    max-width: 1440px;
  }
  h2 {
    text-align: center;
    margin-bottom: 40px;
  }
  .recent-articles-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 20px;
    margin-bottom: 80px;
    @media (max-width: 480px) {
      flex-direction: column;
    }
  }
  .articles-wrapper {
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
  .article-card-with-image {
    flex: 1 0 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
    margin-bottom: 10px;
    overflow: hidden;
    background: #fff;
    // @media(max-width: 768px) {
    //   flex: 1 0 calc(50% - 20px);
    //   max-width: calc(50% - 20px);
    // }
    @media (max-width: 480px) {
      flex: 1 0 100%;
      max-width: none;
    }
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      box-shadow: 0 4px 30px 0 rgba(0, 0, 0, 0.15);
      .image-container {
        img,
        .image-wrapper {
          scale: 1.1;
        }
      }
    }
    .image-container {
      height: 350px;
      overflow: hidden;
      position: relative;
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgba(0, 0, 0, 0.7) 100%);
      }
      img {
        object-fit: cover;
        height: 100%;
        max-width: 100%;
        transition: all 0.25s ease;
      }
      .image-wrapper {
        height: 350px;
        transition: all 0.25s ease;
      }
    }
    .attribution-container {
      padding-left: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #000;
      margin: 10px 0 0 0;
      display: flex;
      justify-content: flex-start;
      gap: 5px;
      line-height: 28px;
      > * {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
        padding: 0 6px;
      }
    }
    .content-container {
      padding: 12px 16px 16px;
    }
    h3 {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 10px;
      line-height: 24.2px;
      color: #000;
      margin: 10px 0 20px;
    }
    p {
      color: #000;
      font-size: 16px;
      line-height: 19.36px;
      font-weight: 400;
    }
  }
  .read-more-trigger-container {
    padding-left: 16px;
  }
  .cta-container {
    margin-bottom: 40px;
    display: flex;
    justify-content: flex-end;
  }
}
