.sticky-phone-bubble-container {
  position: fixed;
  bottom: -200px;
  right: 40px;
  z-index: 11;
  transition: all 0.25s ease;
  text-align: center;
  background: #ff4446;
  padding: 10px 20px;
  color: #fff;
  cursor: pointer;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  @media (max-width: 768px) {
    padding: 20px;
    right: 20px;
  }
  &.show {
    bottom: 20px;
  }
  a {
    flex: 1;
    height: 32px;
    width: 32px;
  }
  svg {
    height: 32px;
    width: auto;
    path {
      fill: #fff;
    }
  }
  @media (max-width: 320px) {
    svg {
      height: 14px;
    }
  }
}
