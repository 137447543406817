.read-more-trigger {
  font-size: 16px;
  color: #eb0004;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 16px 20px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    height: 16px;
    width: auto;
  }
}
